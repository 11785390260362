import { toRef } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useHydrationStore, useSharedPromise } from '#imports'

import type { Marketing } from '@/modules/nuxt-api/models/Marketing'

interface State {
  supportInfo: Marketing & { supportPhoneHref?: string } | null
}

export function useSupportInfoStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const store = useHydrationStore<State>('support-info', {
    supportInfo: null
  }, { disableHydration: true })

  async function initializeSupportInfo () {
    if (store.value.supportInfo) {
      return store.value.supportInfo
    }

    const supportInfo = await nuxtApp.runWithContext(() => useSharedPromise('support-info', () => {
      const { getSupportInfo } = api.marketing()
      return getSupportInfo()
    }))

    if (supportInfo) {
      store.value.supportInfo = supportInfo

      if (supportInfo?.telephone) {
        store.value.supportInfo.supportPhoneHref = `tel:${supportInfo.telephone.replace(/(?!^)\D|\s/g, '')}`
      }
    }

    return store.value.supportInfo
  }

  return {
    initializeSupportInfo,
    supportInfo: toRef(store.value, 'supportInfo')
  }
}
